import React, { useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import classNames from 'classnames'

import { HTMLContent } from 'Content'
import Reveal from 'Reveal'

import useScrollPosition from 'hooks/useScrollPosition'

import ArrowSVG from 'assets/img/svg/arrow-right.svg'

const AboutPageHero = ({ content }) => {
  const [fadeOut, setFadeOut] = useState(false)
  const { scrollPosition } = useScrollPosition(250)

  // Set fade out based on current scrollPosition
  useLayoutEffect(() => {
    if (scrollPosition > 100) {
      setFadeOut(true)
    } else {
      setFadeOut(false)
    }
  }, [scrollPosition])

  return (
    <div className='c-hero' id='top'>
      <div className='o-container--xs u-pos-r o-fill-parent'>
        <div className='c-hero__content'>
          <Reveal ssrReveal bottom>
            <HTMLContent
              content={content}
              className='e-h1 c-hero__title'
            />
          </Reveal>
        </div>
      </div>
      <div className='c-hero-footer c-hero-footer--lg c-hero-footer--overlay'>
        <div className='c-hero-footer__content'>
          <Reveal
            ssrReveal
            delay={1000}
          >
            <AnchorLink href='#content'>
              <ArrowSVG className={classNames('c-scroll-signal a-bounce-down-then-up', {
                'c-scroll-signal--is-hidden': fadeOut
              })}
              />
            </AnchorLink>
          </Reveal>
        </div>
      </div>
    </div>
  )
}

AboutPageHero.propTypes = {
  content: PropTypes.string.isRequired
}

export default AboutPageHero
