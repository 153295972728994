import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Section from 'Section'

import Hero from './AboutPageHero'
import HowWeWork from './AboutPageHowWeWork'
// import TheTeam from './AboutPageTeam'
// import TheBench from './AboutPageBench'
import Services from './AboutPageServices'
import Clients from './AboutPageClients'

const AboutPage = ({ title, body, howWeWork, bench, team, clients, services, className }) => (
  <div className={classNames('c-about', {
    [className]: className
  })}
  >
    <h1 className='u-hide-visually'>{title}</h1>
    <Hero content={body} />
    <Section
      id='content'
      className='c-page-section--fluid@lg'
    >
      <HowWeWork {...howWeWork} />
    </Section>
    {/* <Section className='c-page-section--fluid@lg'>
      <div className='o-container--sm'>
        <div className='o-layout'>
          <div className='o-layout__item u-width-2/3@md'>
            <TheTeam {...team} />
          </div>
          <div className='o-layout__item u-width-1/3@md'>
            <TheBench {...bench} />
          </div>
        </div>
      </div>
    </Section> */}
    <Section className='c-page-section--fluid@lg'>
      <Services {...services} />
    </Section>
    <Section className='c-page-section--fluid@lg'>
      <Clients {...clients} />
    </Section>
  </div>
)

AboutPage.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  howWeWork: PropTypes.object.isRequired,
  bench: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired
}

export default AboutPage
