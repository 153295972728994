import { useState, useLayoutEffect } from 'react'
import throttle from 'lodash.throttle'

const useScrollPosition = (threshold) => {
  const [scrollPosition, setScrollPosition] = useState(null)

  useLayoutEffect(() => {
    window.addEventListener('scroll', throttle(() => {
      setScrollPosition(document.documentElement.scrollTop)
    }, threshold))

    setScrollPosition(document.documentElement.scrollTop)

    return () => window.removeEventListener('scroll', (e) => {
      setScrollPosition(document.documentElement.scrollTop)
    })
  }, [threshold])

  return { scrollPosition }
}

export default useScrollPosition
