import React from 'react'
import { graphql } from 'gatsby'

import Reveal from 'Reveal'
import RemoteSVG from 'RemoteSVG'

const AboutPageClients = ({ title, list }) => (
  <div className='c-clients'>
    <Reveal>
      <h2 className='e-h3 u-ta-c u-mar-b-large'>{title}</h2>
    </Reveal>
    <div className='o-container'>
      <ul className='c-clients-list o-cleanlist'>
        {
          list.map(({ logo, text, name }, index) =>
            <Reveal key={`${index}_${name}`} bottom>
              <li className='c-clients-list-item'>
                <span className='u-hide-visually'>{name}</span>
                <RemoteSVG
                  path={logo}
                  className='c-clients-list-item__logo'
                />
                {
                  text &&
                    <span className='c-clients-list-item__text u-color-grey e-h6'>
                      {text}
                    </span>
                }
              </li>
            </Reveal>
          )
        }
      </ul>
    </div>
  </div>
)

export default AboutPageClients

export const query = graphql`
  fragment ClientsFragment on MarkdownRemarkFrontmatter {
    clients {
      title
      list {
        logo
        text
        name
      }
    }
  }
`
