import React from 'react'

import { HTMLContent } from 'Content'
import Section from 'Section'
import Reveal from 'Reveal'
import BackToTop from 'BackToTop'

import usePreFooter from 'hooks/usePreFooterQuery'

import toHTML from 'utils/toHTML'

const PreFooter = ({ homepage, aboutpage }) => {
  const { textBlocks } = usePreFooter()

  return (
    <>
      <Section>
        <aside className='c-pre-footer'>
          <div className='o-container--xs'>
            {
              textBlocks.map(({ body, hideOnHomepage }, index) => {
                if (!homepage || !hideOnHomepage) {
                  return (
                    <Reveal key={index} bottom>
                      <HTMLContent
                        content={toHTML(body)}
                        key={index}
                        className='c-pre-footer__item u-mar-b-large'
                      />
                    </Reveal>
                  )
                } else {
                  return null
                }
              })
            }
          </div>
        </aside>
      </Section>
      {aboutpage && <BackToTop className='u-mar-b-massive' />}
    </>
  )
}

export default PreFooter
