import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Reveal from 'Reveal'

const Nl2br = ({ rows }) => {
  return rows.split('\n').map((row, rowIndex) => {
    return (
      <Reveal key={rowIndex} bottom>
        {row}
      </Reveal>
    )
  })
}

const AboutPageHowWeWork = ({ title, columnOne, columnTwo, columnThree }) => {
  return (
    <div className='c-about-services'>
      <div className='o-container--sm'>
        <Reveal bottom>
          <h2 className='u-mar-b-huge'>{title}</h2>
        </Reveal>
        <div className='o-layout'>
          <div className='o-layout__item u-width-1/3@md u-mar-b-large u-mar-b-none@md'>
            <Nl2br rows={columnOne} />
          </div>
          <div className='o-layout__item u-width-1/3@md u-mar-b-large u-mar-b-none@md'>
            <Nl2br rows={columnTwo} />
          </div>
          <div className='o-layout__item u-width-1/3@md'>
            <Nl2br rows={columnThree} />
          </div>
        </div>
      </div>
    </div>
  )
}

AboutPageHowWeWork.propTypes = {
  title: PropTypes.string.isRequired,
  columnOne: PropTypes.string.isRequired,
  columnTwo: PropTypes.string.isRequired,
  columnThree: PropTypes.string.isRequired
}

export default AboutPageHowWeWork

export const query = graphql`
  fragment ServicesFragment on MarkdownRemarkFrontmatter {
    services {
      title
      columnOne
      columnTwo
      columnThree
    }
  }
`
