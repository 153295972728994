import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from 'Layout'
import AboutPage from 'AboutPage'
import PreFooter from 'PreFooter'
import SEO from 'SEO'

const AboutPageTemplate = ({ data }) => {
  const {
    frontmatter,
    html,
    fields,
    excerpt
  } = data.markdownRemark

  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        description={excerpt}
        slug={fields.slug}
      />
      <AboutPage
        body={html}
        {...frontmatter}
      />
      <PreFooter aboutpage />
    </Layout>
  )
}

AboutPageTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default AboutPageTemplate

export const query = graphql`
  query {
    markdownRemark(
      frontmatter: {
        templateKey: {
          eq: "pages/about"
        }
      }
    ) {
      id
      html
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        ...HowWeWorkFragment
        ...BenchFragment
        ...TeamFragment
        ...ServicesFragment
        ...ClientsFragment
      }
    }
  }
`
