import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Reveal from 'Reveal'
import { HTMLContent } from 'Content'

import toHTML from 'utils/toHTML'

const AboutPageHowWeWork = ({ title, body }) => (
  <div className='c-about-how-we-work'>
    <div className='o-container--xs'>
      <Reveal bottom>
        <h2 className='e-h3 u-mar-b-huge'>{title}</h2>
        <HTMLContent
          content={toHTML(body)}
          className='e-h2'
        />
      </Reveal>
    </div>
  </div>
)

AboutPageHowWeWork.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired
}

export default AboutPageHowWeWork

export const query = graphql`
  fragment HowWeWorkFragment on MarkdownRemarkFrontmatter {
    howWeWork {
      title
      body
    }
  }
`
