import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { HTMLContent } from 'Content'

const RemoteSVG = ({ path, className }) => {
  const [svg, setSVG] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isErrored, setIsErrored] = useState(false)

  useEffect(() => {
    if (window && window.fetch) {
      window.fetch(path)
        .then(res => res.text())
        .then(setSVG)
        .catch(setIsErrored)
        .then(() => setIsLoaded(true))
    }
  }, [path])

  return (
    <HTMLContent
      className={classNames('o-svg', {
        'o-svg--loaded': isLoaded,
        'o-svg--error': isErrored,
        [className]: className
      })}
      content={svg}
    />
  )
}

export default RemoteSVG
